import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Box,
  CircularProgress,
  IconButton,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Launch as LaunchIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Star,
} from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { useStaticQuery, graphql } from "gatsby";
import jwt_decode from "jwt-decode";
import * as Sentry from "@sentry/gatsby";

import Layout from "../layout";
import PageTitleWrapper from "../components/page/pageTitleWrapper";
import PageTitle from "../components/page/pageTitle";
import {
  getLeads,
  GetLeadsResponse,
  GetLeadsRecord,
} from "../api/endpoints/lead";
import { hasScope } from "../types/userRoles";

interface leadStatus {
  name: string;
  description: {
    description: string;
  };
  crmMappings: string[];
}

interface leadStatusesQuery {
  allContentfulLeadStatus: {
    nodes: leadStatus[];
  };
}

const filterLeads = (
  leads: GetLeadsRecord[] | undefined,
  status: leadStatus
) => {
  var filteredLeads: GetLeadsRecord[] = [];

  //Filter out all leads with the specified status
  if (leads != undefined) {
    leads.forEach((item) => {
      status.crmMappings.forEach((crmMap) => {
        if (item.status == crmMap) {
          filteredLeads.push(item);
          return;
        }
      });
    });
  }

  return filteredLeads;
};

const formatDate = (date: string, includeTime?: boolean) => {
  const parsedDate = new Date(date);

  if (parsedDate) {
    if (includeTime) {
      return (
        parsedDate.toDateString().substring(4) +
        " " +
        parsedDate.getHours() +
        ":" +
        parsedDate.getMinutes() +
        ":" +
        parsedDate.getSeconds()
      );
    } else {
      return parsedDate.toDateString().substring(4);
    }
  }

  return "";
};

const ViewLeadsPage = () => {
  const data = useStaticQuery<leadStatusesQuery>(graphql`
    query {
      allContentfulLeadStatus(sort: { order: ASC, fields: order }) {
        nodes {
          name
          description
          crmMappings
        }
      }
    }
  `);

  //COMPONENT STATE

  const [pageLoading, setPageLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsOffset, setRecordsOffset] = useState(0);
  const [leadData, setLeadData] = useState<GetLeadsRecord[]>();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<GetLeadsRecord>();

  //HOOKS
  const theme = useTheme();
  const downLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchLeads = async () => {
      if (user) {
        //Retrieve access token to make API call
        const token = await getAccessTokenSilently();

        //Populate leads
        populateLeads(token, 0, false);
      }
    };

    //TODO: Deal with errors
    fetchLeads();
  }, [user]);

  const populateLeads = (
    token: string,
    recordsOffset: number,
    appendToExistingRecords: boolean
  ) => {
    //Get scopes
    const decodedToken = jwt_decode(token);

    const viewAllLeads = hasScope(decodedToken, "viewAll:leads");
    const viewAllOrgLeads = hasScope(decodedToken, "viewAllOrganization:leads");

    //Set filter parameters based on role
    const orgId = !viewAllLeads ? user?.org_id : null;
    const username = !viewAllLeads && !viewAllOrgLeads ? user?.email : null;

    //Fetch leads
    getLeads(20, recordsOffset, orgId, username as string, token)
      .then((response) => {
        var getResponse = response.data as GetLeadsResponse;

        if (getResponse.code == "OK") {
          //Set lead data
          if (appendToExistingRecords) {
            var combineLeadData = leadData?.concat(getResponse.records);
            setLeadData(combineLeadData);
          } else {
            setLeadData(getResponse.records);
          }

          //Update offset for next requests and total
          setRecordsOffset(getResponse.nextOffset);
          setTotalRecords(getResponse.totalNoOfRecords);
        }

        //Remove loader
        setPageLoading(false);
        setLoadMoreLoading(false);
      })
      .catch((err) => {
        Sentry.configureScope(function (scope) {
          scope.setTag("email", user?.email);
        });
        Sentry.captureException(err);

        //Remove loader
        setPageLoading(false);
        setLoadMoreLoading(false);
      });
  };

  //HANDLERS

  const handleOnClickLoadMore = async () => {
    //Disable button and show as loading
    setLoadMoreLoading(true);

    //Retrieve access token to make API call
    const token = await getAccessTokenSilently();

    //Populate leads
    populateLeads(token, recordsOffset, true);
  };

  const handleShowLeadDetailsClick = (id: string) => {
    leadData?.forEach((item) => {
      if (item.id == id) {
        setSelectedRecord(item);
        setShowDialog(true);
      }
    });
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <Layout>
      <PageTitleWrapper>
        <PageTitle
          heading="My Leads"
          subHeading="View of all leads that have been generated and what status they're at in the process."
        />
      </PageTitleWrapper>
      {!pageLoading && (
        <Box px={2}>
          <Box width={downLargeScreen ? "100%" : "87%"} textAlign="right">
            <Typography variant="body2">
              Currently displaying {leadData?.length} of {totalRecords} records
            </Typography>
          </Box>
          <Grid container spacing={1}>
            {data.allContentfulLeadStatus.nodes.map((item, index) => (
              <Grid item xs={12} md={2.4} lg={2.1} key={index}>
                <Card
                  sx={{ width: "100%", my: 1, backgroundColor: "primary.main" }}
                >
                  <CardHeader
                    title={
                      <Typography variant="body2" sx={{ color: "white", fontSize: "16px !important" }}>
                        {item.name}
                      </Typography>
                    }
                  />
                </Card>
                <Grid container>
                  {filterLeads(leadData, item)?.map((lead, leadIndex) => (
                    <Grid item xs={12} key={leadIndex}>
                      <Card sx={{ my: 1 }}>
                        <CardHeader
                          title={
                            <Typography
                              variant="body2"
                              sx={{ color: "primary.dark", fontWeight: "bold" }}
                            >
                              {lead.businessName ?? "Unknown"}
                            </Typography>
                          }
                          subheader={lead.firstName + " " + lead.lastName}
                          titleTypographyProps={{}}
                          subheaderTypographyProps={{ fontSize: 13 }}
                          sx={{ pt: 2 }}
                        />
                        <Grid container>
                          <Grid item xs={6}>
                            <Box fontSize={12} pl={2}>
                              {formatDate(lead.dateCreated)}
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box textAlign="right" width="100%" p={0.5}>
                              <IconButton
                                onClick={() =>
                                  handleShowLeadDetailsClick(lead.id)
                                }
                              >
                                <LaunchIcon />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Box width={downLargeScreen ? "100%" : "87%"}>
            {recordsOffset >= 0 && leadData != null && leadData.length > 0 && (
              <Box mt={2} mb={4}>
                <LoadingButton
                  onClick={() => handleOnClickLoadMore()}
                  variant="contained"
                  color="secondary"
                  loading={loadMoreLoading}
                  fullWidth
                >
                  <KeyboardArrowDownIcon />
                  Load More
                </LoadingButton>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {pageLoading && (
        <Box mt={20} textAlign="center">
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              animationDuration: "550ms",
            }}
            size={25}
            thickness={6}
          />
        </Box>
      )}
      <Dialog
        fullScreen={downLargeScreen ? true : false}
        open={showDialog}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography variant="h3" color="primary">
            {selectedRecord?.businessName}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <strong>Contact Name</strong>
            </Grid>
            <Grid item xs={8}>
              {selectedRecord?.firstName} {selectedRecord?.lastName}
            </Grid>
            <Grid item xs={4}>
              <strong>Mobile</strong>
            </Grid>
            <Grid item xs={8}>
              {selectedRecord?.mobile}
            </Grid>
            <Grid item xs={4}>
              <strong>Landline</strong>
            </Grid>
            <Grid item xs={8}>
              {selectedRecord?.landline}
            </Grid>
            <Grid item xs={4}>
              <strong>Email Address</strong>
            </Grid>
            <Grid item xs={8}>
              {selectedRecord?.email}
            </Grid>
            <Grid item xs={4}>
              <strong>Status</strong>
            </Grid>
            <Grid item xs={8}>
              {selectedRecord?.status}
            </Grid>
            <Grid item xs={4}>
              <strong>Date Created</strong>
            </Grid>
            <Grid item xs={8}>
              {formatDate(selectedRecord?.dateCreated ?? "", true)}
            </Grid>
            <Grid item xs={4}>
              <strong>Organization</strong>
            </Grid>
            <Grid item xs={8}>
              {selectedRecord?.organizationName == "null"
                ? "Unknown"
                : selectedRecord?.organizationName}
            </Grid>
            <Grid item xs={4}>
              <strong>Agent</strong>
            </Grid>
            <Grid item xs={8}>
              {selectedRecord?.organizationContactName == "null"
                ? "Unknown"
                : selectedRecord?.organizationContactName}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default ViewLeadsPage;

import { User } from "@auth0/auth0-react";

const isInRole = (user:User, role:string) => {

    var roles:string[] = user["https://digibin.wastequotesupermarket.co.uk/roles"];
    var inRole = false;

    //Loop through roles and check if in specified role
    roles.forEach((item) => {

        if(item == role) {

            inRole = true;
            return;
        }

    });

    return inRole;
}

const hasScope = (token:any, scope:string) => {

    if(token.scope.indexOf(scope) >= 0) {
        return true;
    }

    return false;
}

export default isInRole
export { hasScope }